
import { Options, Vue } from 'vue-class-component'
import theSponsorList from '@/components/Frontend/SponsorList/index.vue'

@Options({
  components: {
    theSponsorList
  }
})

export default class Sponsorlist extends Vue {}
